import { Button, Checkbox, Form, Modal, notification, Upload } from 'antd'
import React, { useState } from 'react'
import FormFields from '../../../../component/common/FormFields';
import { UploadOutlined, InboxOutlined } from "@ant-design/icons";
import TaskSubmissionModal from './task-submission-modal';
import WithdrawModal from './withdraw-modal';


const TaskApply = ({ admindetails, setRefresh, show = false, status, setStatus, taskData, applyUserTaskHandler, isModalVisible }) => {
    const [form] = Form.useForm();
    const [checked, setChecked] = useState(false);
    return (
        <>
            <Modal width={440}
                open={show}
                onCancel={() => {
                    form?.resetFields();
                    setChecked(false)
                    isModalVisible()
                }}
                cancelButtonProps={{ style: { display: "none" } }}
                okButtonProps={{ style: { display: "none" } }}  >
                {/* APPLY FORM */}
                {status === "Apply" ? <div>
                    <p className='py-2 font-semibold'>
                        Terms & Condition
                    </p>
                    <div className='py-2' dangerouslySetInnerHTML={{ __html: taskData?.termAndCondition }} />
                    <div className='py-4'>
                        <Checkbox onChange={(e) => {
                            setChecked(e?.target?.checked)
                        }}>I have read terms & conditions. and i'm accepted.</Checkbox>

                    </div>
                    <Button className='w-full' type='primary' color='#DBECFF80' style={{
                        background: "#DBECFF80",
                        color: "#007BFF",
                    }} onClick={() => {
                        if (!checked) return notification.error({ message: "Please accept terms & conditions" })

                        applyUserTaskHandler(isModalVisible());
                    }}>
                        Apply
                    </Button>
                </div> : status === "InProgress" || status === "Reject" || status === "Rework" ?
                    <TaskSubmissionModal
                        form={form}
                        taskData={taskData}
                        admindetails={admindetails}
                        setRefresh={setRefresh}
                        isModalVisible={isModalVisible}
                        setStatus={setStatus} /> :
                    status === "Pending" ?
                        <WithdrawModal
                            taskData={taskData}
                            isModalVisible={isModalVisible}
                            setRefresh={setRefresh} /> : <></>}
            </Modal>
        </>
    )
}

export default TaskApply