import { Card, Col, Image, Row } from 'antd'
import React from 'react'

const YoutubeCard = ({ taskData }) => {
    return (
        <Row gutter={[16, 16]} className="">
            {taskData?.visibleOnApp && taskData?.helpLink && <Col className="" span={24} sm={12} md={12} lg={24}>
                <Card className="">
                    <p className="font-semibold text-primary py-2">How to do this work?</p>
                    <div className="flex items-center justify-center ">
                        <iframe className='video rounded-lg w-full sm:w-[90%] '
                            title='Youtube player'
                            sandbox='allow-same-origin allow-forms allow-popups allow-scripts allow-presentation'
                            // src={`https://youtube.com/embed/IEDEtZ4UVtI?autoplay=0`}
                            src={taskData?.helpLink}
                        >
                        </iframe>
                    </div>
                </Card>
            </Col>}
            {taskData?.visibleOnApp && taskData?.banner && <Col className="" span={24} sm={12} md={12} lg={24}>
                <Card className="h-full">
                    <p className="font-semibold py-2">Live Webinar</p>
                    {/* <div className="flex justify-center items-center">
                        <a href='https://www.youtube.com/watch?v=IEDEtZ4UVtI' target='_blank'>
                            <Image
                                src="https://via.placeholder.com/500x300.png"
                                className="absolute  w-full h-full object-cover rounded-xl"
                                style={{
                                    aspectRatio: '2.63:1',
                                }}
                                wrapperStyle={{ aspectRatio: '2:1' }}
                                width={340}
                                height={130}
                                alt="Training banner"
                                preview={false}
                            />
                        </a>
                    </div> */}
                    <div className="relative flex justify-center">
                        <a style={{
                            aspectRatio: '2.63:1',
                        }} className='relative  flex justify-center' href='https://www.youtube.com/watch?v=IEDEtZ4UVtI' target='_blank'>
                            <Image
                                src={taskData?.banner}
                                alt="Training banner"
                                className=" object-cover rounded-xl"
                                fill
                                // width={410}
                                // height={156}
                                preview={false}
                            />
                        </a>
                    </div>

                </Card>
            </Col>}
        </Row>
    )
}

export default YoutubeCard