import React from 'react'
import FormFields from '../common/FormFields'
import { Button, notification } from 'antd'
import CONSTANTS, { appRoot } from '../../util/constant/CONSTANTS'
import useHttp from '../../hooks/use-http'
import { useNavigate } from 'react-router-dom'
import { payloadGenerator } from '../common/CRUD-Component'
import axios from 'axios'
import { setAuthDetails } from '../../util/API/authStorage'

const RegistrationCard = ({ admindetails, form, editId = null, setEditId, setRefresh }) => {
    const api = useHttp();
    const navigate = useNavigate();

    const handleFormSubmit = () => {
        form
            .validateFields()
            .then((values) => {
                const valu = {
                    ...values,
                    isAffiliateUser: true
                }

                const payload = payloadGenerator(editId ? values : valu, editId ? CONSTANTS.FORM_FIELD.REGISTRATION_PAGE_EDIT_MODAL : CONSTANTS.FORM_FIELD.REGISTRATION_PAGE_MODAL, true);

                if (editId) {
                    api.sendRequest(CONSTANTS.API.editProfile, (res) => {
                        setEditId(null);
                        setRefresh(pr => !pr)
                    }, payload, "Your detail sucessfully upadated")
                } else {
                    if (localStorage.getItem("userType") === "old") {
                        api.sendRequest(
                            CONSTANTS.API.signUp, (res) => {
                                // console.log(res);
                                //navigation link================================
                                navigate(appRoot);
                                window.location.assign(appRoot);

                            }, payload, "Your detail sucessfully saved",
                        )
                    } else {
                        const signupToken = localStorage.getItem("signupToken");

                        const options = {
                            headers: {
                                Accept: "application/json",
                                Authorization: `Bearer ${signupToken}`, // Add the token as Authorization header
                            },
                        };

                        axios
                            .post(`${process.env.REACT_APP_BASE_URL}${CONSTANTS.API.signUp?.endpoint}`, payload, options) // postData goes first, options go last
                            .then((res) => {
                                setAuthDetails(res?.data?.token);

                                notification.success({
                                    message: "Your detail sucessfully saved",
                                })
                                localStorage.setItem("userType", "old");
                                navigate(appRoot);

                            })
                            .catch((error) => {
                                console.error("Error during sign up:", error);
                            });
                    }

                }
            })
            .catch((info) => {
                console.log("Validate Failed:", info);
            });
    };
    return (
        <>
            <p className='text-primary text-2xl font-bold pt-2'>Enter Your Details</p>
            <FormFields
                formData={{
                    country: "India",
                    mobile: +localStorage.getItem("mobileno"),
                    ...admindetails
                }}
                form={form}
                formFields={editId ? CONSTANTS.FORM_FIELD.REGISTRATION_PAGE_EDIT_MODAL : CONSTANTS.FORM_FIELD.REGISTRATION_PAGE_MODAL}
            />
            <div className='flex justify-center items-center py-10'>
                <Button
                    key="submit"
                    className='text-center'
                    style={{
                        borderRadius: "9px",
                        height: "40px",
                        width: "340px",
                    }}
                    type="primary"
                    onClick={handleFormSubmit}
                    loading={api.loading}
                >
                    {"Save & Continue"}
                </Button>
            </div>
        </>
    )
}

export default RegistrationCard