import moment from "moment";
import { getAuthToken } from "./API/authStorage";
import { PiTelegramLogoLight } from "react-icons/pi";
import { MdAccessTime } from "react-icons/md";
import { TbInfoTriangle, TbRefreshAlert } from "react-icons/tb";
import { IoMdCloseCircle, IoMdCloseCircleOutline } from "react-icons/io";
import { BsEmojiFrown } from "react-icons/bs";
import starIcon from "../asset/image/ratings.png";
import { Button, Image } from "antd";

export const apiGenerator = (apiObject, exchangePair = {}, join = null) => {
  const apiObj = { ...apiObject };
  if (Object.keys(exchangePair).length) {
    Object.keys(exchangePair).forEach((el) => {
      apiObj.endpoint = apiObj.endpoint.replace(`:${el}`, exchangePair[el]);
    });
  }

  if (join) {
    apiObj.endpoint = `${apiObj.endpoint}${join}`;
  }
  return apiObj;
};

export const isMobile = () => {
  return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) || window.innerWidth <= 768;
};

export const isLogin = () => {
  return getAuthToken() !== undefined && getAuthToken() !== null;
};

export const convertLocalToUTC = (localTime, format = 'YYYY-MM-DDTHH:mm:ss', utcFormat = 'YYYY-MM-DDTHH:mm:ss') => {
  return moment.utc(localTime, utcFormat).format(format);
}

export const convertUTCToLocal = (utcTime, format = 'YYYY-MM-DD HH:mm:ss') => {
  const localTime = moment.utc(utcTime).local().format(format);
  return localTime;
};


export const generateMenuFromRoutes = (routes = [], role, basePath = "") => {
  let menu = [];
  const targetedRole = role

  routes?.forEach((route) => {
    let nestedBasePath = route?.index
      ? basePath
      : route?.path
        ? route.path
        : "/";
    nestedBasePath = nestedBasePath.trim().replace("//", "/");
    const menuItem = {
      id: nestedBasePath,
      icon: route?.icon,
      label: route?.label,
      key: nestedBasePath,
    };

    if (route?.children && route?.children?.length > 0) {
      // Recursive call for nested children

      const children = generateMenuFromRoutes(
        route?.children,
        role,
        nestedBasePath
      );
      if (children.length) menuItem.children = children;
    }
    if (route?.icon && (!route?.Role || route?.Role?.some(r => targetedRole?.includes(r)))) {
      menu?.push(menuItem);
    } else if (
      menuItem?.children?.length &&
      (!route?.Role || route?.Role?.some(r => targetedRole?.includes(r)))
    ) {
      menu?.push(...menuItem.children);
    }
  });
  return menu;
};

export const generateRoutes = (routes = [], role) => {
  // console.log(routes);
  const targetedRole = role
  let Routes = [];
  routes?.forEach((route) => {
    const mainRoute = { ...route };
    if (route?.children && route?.children?.length > 0) {
      // Recursive call for nested children

      const children = generateRoutes(route?.children, role);
      if (children.length) mainRoute.children = children;
    }
    if (!route?.Role || route?.Role?.some(r => targetedRole?.includes(r))) {
      Routes?.push(mainRoute);
    } else if (
      mainRoute?.children?.length &&
      (!route?.Role || route?.Role?.some(r => targetedRole?.includes(r)))
    ) {
      Routes?.push(...mainRoute.children);
    }
  });
  // console.log(Routes, 'rou');
  return Routes;
};

export const PERMISSION = {
  READ: 0,
  WRITE_WITH_OUT_DELETE: 1,
  ALL: 2,
  0: 'READ',
  1: 'WRITE_WITH_OUT_DELETE',
  2: 'ALL'

}

export const DEFAUlT_PERMISSION = [
  `admin-${PERMISSION.READ}`,
  `admin-${PERMISSION.WRITE_WITH_OUT_DELETE}`,
  `admin-${PERMISSION.ALL}`,
]

export const getClearObject = (obj = {}) => {
  let newObj = {};
  Object.keys(obj).map((key) => {
    let item = obj[key];
    if (typeof item === "boolean") {
      newObj[key] = item;
    } else if (typeof item === "number") {
      newObj[key] = item;
    } else if (typeof item === "string") {
      if (item) {
        newObj[key] = item;
      }
    } else if (Array.isArray(item)) {
      if (item.length) {
        newObj[key] = item;
      }
    } else if (typeof item === "object") {
      // if object is type of date
      if (item instanceof Date) {
        if (!isNaN(item)) {
          newObj[key] = item;
        }
      } else if (Object?.keys(item || {})?.length) {
        newObj[key] = item;
      }
    }
  });

  return newObj;
};

export const TASK_STATUS_COLOR = {
  'Active': '#007BFF',
  'Completed': '#28A745',
  'Applied': '#7F7F7F',
  'Interview': '#FA8C16',
  'Rejected': '#FF4D4F',
  'Accepted': '#52C41A',
}

export const TASK_LABEL_OPTION = [
  {
    id: 1,
    label: 'None',
    value: null
  },
  {
    id: 2,
    label: 'New',
    value: 'new'
  },
  {
    id: 3,
    label: 'Popular',
    value: 'popular'
  },
  {
    id: 4,
    label: 'Hot',
    value: 'hot'
  },
]

export const TASK_APPROVAL_OPTION = [
  {
    id: 1,
    label: "Accepted",
    value: "Accepted",
  },
  {
    id: 2,
    label: "Reject",
    value: "Reject",
  },
  {
    id: 3,
    label: "Rework",
    value: "Rework",
  },
];

export const TASK_APPROVAL_FORMS = {
  Accepted: {
    message: "Task Approved",
    modaltitle: "Accept",
    form: "TASK_APPROVAL_MODAL"
  },
  Reject: {
    message: "Task Rejected",
    modaltitle: "Reject",
    form: "TASK_REJECT_MODAL"
  },
  Rework: {
    message: "Rework on Task",
    modaltitle: "Rework",
    form: "TASK_REMOVE_MODAL"
  },
}

export const TASK_FORM_OPTION = [{ label: 'jpeg', value: 'jpeg' },
{ label: 'jpg', value: 'jpg' },
{ label: 'png', value: 'png' },
{ label: 'pdf', value: 'pdf' },
{ label: 'mp4', value: 'mp4' },
{ label: 'ppt', value: 'ppt' },
{ label: 'docx', value: 'docx' },
{ label: 'excel', value: 'excel' },
{ label: 'word', value: 'word' },]

export const maskEmail = (email) => {
  if (!email) {
    return '';
  }

  const atIndex = email.indexOf('@');
  if (atIndex <= 1) {
    // If "@" is at or near the beginning, only show the first character
    return email.charAt(0) + '*'.repeat(email.length - 1);
  } else {
    const visibleCharacters = 2; // Number of characters to show before "@"
    const maskedCharacters = email.length - visibleCharacters - 1;
    const maskedPart = '*'.repeat(maskedCharacters);
    return email.substring(0, visibleCharacters) + maskedPart + email.substring(atIndex);
  }
};

export const notesCard = (key, data) => {
  const notesData = {
    Reject: {
      icon: <TbInfoTriangle size={24} color="#dc3545" />,
      title: `Resubmit the work within ${data?.numberOfDaysForResubmit} days.`,
      description: data?.adminMessage ? data?.adminMessage : "It is a long established fact that a reader will be distracted by the readable.",
      color: "#dc3545",
    },
    Rework: {
      icon: <TbRefreshAlert size={24} color="#722ED1" />,
      title: `Resubmit the work within ${data?.numberOfDaysForResubmit} days.`,
      description: data?.adminMessage ? data?.adminMessage : "It is a long established fact that a reader will be distracted by the readable.",
      color: "#722ED1",
    },
    Expired: {
      icon: <BsEmojiFrown size={24} color="#7D8995" />,
      title: "Resubmission time limit expired.",
      description: "The time limit given to you has expired, so you can no longer submit this task. But, there are many other tasks, you can complete those tasks.",
      color: "#343A40",
    },
    Inactive: {
      icon: <IoMdCloseCircleOutline size={24} color="#7D8995" />,
      title: "Task Inactive",
      description: "This task has ended. But, there are many other tasks, you can complete those tasks.",
      color: "#343A40",
    },
    Accepted: {
      icon: <div className="h-full flex items-center">
        <Image preview={false} src={starIcon} width={36} height={36} />
        <p className="text-2xl font-semibold">4.5</p></div>,
      title: "Congratulation!",
      description: "Your work has been accepted.",
      color: "#28A745",
      position: "reverse"
    },
  };

  const note = notesData[key];

  if (!note) return <></>;

  return (
    <div className={`py-2 my-2 px-3 border-[1px] border-[${note.color}] border-solid rounded-lg flex flex-row${note?.position ? "-" + note.position : ""} gap-2`}>
      <div>{note.icon}</div>
      <div className="w-full">
        <p className={`text-[${note.color}] font-semibold mb-1`}>{note.title}</p>
        <p className="text-xs py-2 text-[#343A40]">{note.description}</p>
      </div>
    </div>
  );
};


export const statusCard = (key) => {
  // const statusData = {
  //   InProgress: {
  //     icon: <PiTelegramLogoLight color="#343A40" />,
  //     bgcolor: "#D4D8DC4D",
  //     color: "#343A40",
  //     label: "Applied",
  //   },
  //   Pending: {
  //     icon: <MdAccessTime color="#FA8C16" />,
  //     bgcolor: "#FA8C161A",
  //     color: "#FA8C16",
  //     label: "Inreview",
  //   },
  //   Reject: {
  //     icon: <TbInfoTriangle color="#DC3545" />,
  //     bgcolor: "#F9DDE080",
  //     color: "#DC3545",
  //     label: "Rejected",
  //   },
  //   Rework: {
  //     icon: <TbRefreshAlert color="#722ED1" />,
  //     bgcolor: "#722ED112",
  //     color: "#722ED1",
  //     label: "Rework",
  //   },
  //   Expired: {
  //     icon: <BsEmojiFrown color="#343A40" />,
  //     bgcolor: "#D4D8DC4D",
  //     color: "#343A40",
  //     label: "Expired",
  //   },
  //   Inactive: {
  //     icon: <IoMdCloseCircle color="#343A40" />,
  //     bgcolor: "#D4D8DC4D",
  //     color: "#343A40",
  //     label: "Inactive",
  //   },
  //   Completed: {
  //     icon: <PiTelegramLogoLight color="#28A745" />,
  //     bgcolor: "#DCF7E280",
  //     color: "#28A745",
  //     label: "Completed",
  //   },
  // };
  const statusData =
  {
    InProgress: <div className={`bg-[#D4D8DC4D] text-[#343A40] rounded-[30px] flex items-center px-3 py-2`}>
      <PiTelegramLogoLight color="#343A40" />
      <p className={`font-semibold text-[#343A40] text-xs ml-1`}>{"Applied"}</p>
    </div>,
    Pending: <div className={`bg-[#FA8C161A] text-[#FA8C16] rounded-[30px] flex items-center px-3 py-2`}>
      <MdAccessTime color="#FA8C16" />
      <p className={`font-semibold text-[#FA8C16] text-xs ml-1`}>{"Inreview"}</p>
    </div>,
    Reject: <div className={`bg-[#F9DDE080] text-[#DC3545] rounded-[30px] flex items-center px-3 py-2`}>
      <TbInfoTriangle color="#DC3545" />
      <p className={`font-semibold text-[#DC3545] text-xs ml-1`}>{"Rejected"}</p>
    </div>,
    Rework: <div className={`bg-[#722ED112] text-[#722ED1] rounded-[30px] flex items-center px-3 py-2`}>
      <TbRefreshAlert color="#722ED1" />
      <p className={`font-semibold text-[#722ED1] text-xs ml-1`}>{"Rework"}</p>
    </div>,
    Expired: <div className={`bg-[#D4D8DC4D] text-[#343A40] rounded-[30px] flex items-center px-3 py-2`}>
      <BsEmojiFrown color="#343A40" />
      <p className={`font-semibold text-[#343A40] text-xs ml-1`}>{"Expired"}</p>
    </div>,
    Inactive: <div className={`bg-[#D4D8DC4D] text-[#343A40] rounded-[30px] flex items-center px-3 py-2`}>
      <IoMdCloseCircle color="#343A40" />
      <p className={`font-semibold text-[#343A40] text-xs ml-1`}>{"Inactive"}</p>
    </div>,
    Accepted: <div className={`bg-[#DCF7E280] text-[#28A745] rounded-[30px] flex items-center px-3 py-2`}>
      <PiTelegramLogoLight color="#28A745" />
      <p className={`font-semibold text-[#28A745] text-xs ml-1`}>{"Completed"}</p>
    </div>,
  };
  //   return <div className="bg-[#D4D8DC4D] rounded-[30px] flex items-center px-3 py-2">
  //   <PiTelegramLogoLight /> <p className="font-semibold text-xs">Applied</p>
  // </div>
  const status = statusData[key];
  if (!status) return <></>;
  return (statusData?.[key] || <></>);
  // <div className={`bg-[${status?.bgcolor}] text-[${status?.color}] rounded-[30px] flex items-center px-3 py-2`}>
  //   {status.icon} <p className={`font-semibold text-[${status?.color}] text-xs ml-`}>{status.label}</p>
  // </div>
  // );
};
export const buttonChange = ({ status, applyUserTaskHandler, isModalVisible }) => {

  if (status === "Apply") {
    return <Button type="primary" className="mt-16 w-full py-6 rounded-lg text-lg" onClick={() => isModalVisible()}>
      Apply
    </Button>
  } else if (status === "InProgress" || status === "Reject" || status === "Rework") {
    return <div className="mt-16">
      <p className='text-center text-[#7D8995] mb-1'>Submit Proof of work</p>
      <Button type="dashed" onClick={() => isModalVisible()} style={{
        borderColor: "#007BFF",
        color: "#007BFF"
      }} className="w-full py-5 hover:!bg-[#DBECFF] rounded-lg text-lg">
        + Submission
      </Button>
    </div>
  } else if (status === "Pending") {
    return <div className="mt-16">
      <p className='text-center text-[#7D8995] mb-1'>You can withdraw a request to submitted work</p>
      <Button onClick={() => isModalVisible()} type="primary" danger className="w-full py-6  rounded-lg text-lg">
        Withdraw Submission
      </Button>
    </div>
  }
  else {
    return <></>
  }
}