import React, { useEffect, useState } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ALL_ROUTES from "./util/Route";
import { apiGenerator, isLogin } from "./util/functions";
import useHttp from "./hooks/use-http";
import CONSTANTS from "./util/constant/CONSTANTS";
function App() {
  const [admindetails, setAdmindetails] = useState(null);
  const [userTaskData, setuserTaskData] = useState(null);
  const [taskData, setTaskData] = useState(null);

  const [refresh, setRefresh] = useState(false);
  const [userData, setUserData] = useState({});


  const api = useHttp();
  useEffect(() => {
    const userId = localStorage.getItem("userId");
    const userTaskId = localStorage.getItem("userTaskId");
    if (userTaskId && userId) {
      api.sendRequest(
        apiGenerator(CONSTANTS.API.getClient, {}, `?userTaskId=${userTaskId}&userId=${userId}`), (res) => {
          localStorage.setItem("taskId", res?.data?.taskId);;
          setUserData(res?.data?.user)
        }
      )
    }

    if (!isLogin()) return;
    const GET_MY_PROFILE_API = { ...CONSTANTS.API.getMe }
    api.sendRequest(GET_MY_PROFILE_API, (res) => {
      setAdmindetails(res?.data);
      // localStorage.setItem("taskId", res?.data?.taskId);
    });
    const taskId = localStorage.getItem("taskId");
    if (taskId) {
      api.sendRequest(apiGenerator(CONSTANTS.API.task.getOne, {
        dataId: taskId //taskid // 
      }), (res) => {
        setTaskData(res?.data);
        // setStatus(res?.data?.userTasks?.[0]?.status ?? "")
      })
    }
    // const userTaskId = localStorage.getItem("userTaskId")
    // if (userTaskId) {
    //   api.sendRequest(apiGenerator(CONSTANTS.API.userTask.getOne, {
    //     dataId: +userTaskId
    //   }), (res) => {
    //     setuserTaskData(res?.data)
    //   });
    // }
  }, [isLogin, refresh])

  const router = createBrowserRouter(ALL_ROUTES({
    admindetails,
    // userTaskData,
    userData,
    taskData,
    setRefresh
  }));
  return <RouterProvider router={router} />;
}

export default App;
