import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Avatar, Button, Col, Drawer, Dropdown, Form, Image, Layout, Menu, Modal, Row } from "antd";
import { useEffect, useState } from "react";
import { appRoot, loginRoot } from "../../util/constant/CONSTANTS";
import Profile from "../../asset/image/dummy-avatar.jpg";
import { getAuthToken } from "../../util/API/authStorage";
import { deleteAuthDetails } from "../../util/API/authStorage";
import logo from "../../asset/icons/Headerlogo.svg";
import { CgProfile } from "react-icons/cg";
import { MdOutlineSupportAgent } from "react-icons/md";
import { TbLogout } from "react-icons/tb";
import RegistrationCard from "../modalCard/registrationCard";
import { isLogin, isMobile } from "../../util/functions";
import dummyAvatar from "../../asset/image/dummy-avatar.jpg";

const { Header, Content, Sider } = Layout;

const AppLayout = ({ admindetails, setRefresh, userData }) => {

  const navigate = useNavigate();
  const location = useLocation();
  const [editId, setEditId] = useState(null)
  const [visible, setVisible] = useState(false);
  const storedUserTaskId = localStorage.getItem("userTaskId");
  const storedUserId = localStorage.getItem("userId");
  // if()
  const [form] = Form.useForm();

  const pathMatch = location?.pathname?.split("/");
  useEffect(() => {

    if (location?.pathname?.includes("task") && pathMatch?.length > 2) {
      const userTaskId = location?.pathname?.split("/")?.[2]
      const userId = location?.pathname?.split("/")?.[3]
      if (!storedUserTaskId && !storedUserId) {
        localStorage.setItem("userTaskId", userTaskId);
        localStorage.setItem("userId", userId);
        return navigate(loginRoot);

      }
      // return storeIds(userTaskId, userId, navigate);
    }

    if (!(getAuthToken() !== undefined && getAuthToken() !== null)) {
      navigate(loginRoot);
      return;
    }
    // else
    // if (!location?.pathname?.includes("task") && pathMatch?.length === 3) {
    //   const userTaskId = location?.pathname?.split("/")?.[1]
    //   const userId = location?.pathname?.split("/")?.[2]

    //   storeIds(userTaskId, userId);
    // }
  }, []);
  useEffect(() => {
    // const userId = localStorage.getItem("userId");
    // const userTaskId = localStorage.getItem("userTaskId");
    // if (!userTaskId || !userId) return navigate("/error")

    const userRole = localStorage.getItem("userType");

    if (userRole && userRole !== "old") return navigate("/registration");
    if (storedUserTaskId && storedUserId && isLogin()) {
      const genPath = `${appRoot}/${storedUserTaskId}/${storedUserId}`
      if (location?.pathname === genPath) return
      return navigate(genPath);
    }
  }, [admindetails]);

  const onClose = () => {
    setVisible(false);
  };
  const handleLogout = () => {
    deleteAuthDetails();
    localStorage.clear();

    navigate("/");
  };

  const items = [
    {
      key: '1',
      label: "Profile",
      icon: <CgProfile size={19} />, // Profile icon
      onClick: () => setEditId(admindetails?.id),
    },
    {
      key: '2',
      label: "Help",
      icon: <MdOutlineSupportAgent size={19} />, // Help icon
      onClick: () => window.open(`https://api.whatsapp.com/send?phone=${userData?.mobile}`, '_blank'),
    },
    {
      key: '3',
      label: "Logout",
      icon: <TbLogout style={{ color: 'red' }} size={19} />, // Logout icon with red color
      onClick: handleLogout, // Call handleLogout when clicked
    },
  ];

  return (
    <>
      <Layout
        hasSider={true}
        style={{
          minHeight: "100vh",
          // userSelect: "none",
        }}
      >

        <Layout className="site-layout">
          <Header className="flex justify-between flex-col !bg-white h-[70px] py-1 px-0"
            style={{
              // padding: "5px 0px",
              // height: "70px",

            }}
          >
            <Row
              align="middle"
              justify="space-between"
              style={{ padding: "0px 35px" }}
            >
              <Col span={16} className="flex items-center flex gap-2">
                <Avatar
                  src={
                    userData?.image ?? dummyAvatar
                  }
                  size={60}
                  style={{ border: "1px solid #dce0e6", }}
                />
                <div className=""> <p className="text-primary text-[14px] xs:text-[13px] font-semibold leading-6">{"Team Leader"}</p>
                  <p className="text-[16px] xs:text-[12px] leading-6 ">{"Rohan Raj Gajera"}</p></div>
              </Col>
              <Col className="flex justify-end" span={8}>
                <Dropdown
                  menu={{ items }}
                  placement="bottomRight"
                  arrow
                  trigger={["click"]}
                >
                  <Avatar
                    src={
                      admindetails?.image ?? dummyAvatar
                    }
                    size={50}
                    style={{
                      border: "1px solid #dce0e6",
                      cursor: "pointer",
                    }}
                  />
                </Dropdown>
              </Col>

            </Row>
          </Header>
          <Content
            style={{
              margin: "0 16px",
            }}
          >
            <Outlet />

            {/* {getAuthToken() !== undefined && getAuthToken() !== null && (
              <Outlet />
            )} */}
          </Content>
        </Layout>
      </Layout>
      {/* <div> */}
      <Drawer
        placement="right"
        closable={false}
        onClose={onClose}
        open={visible}
      >
        <div className="flex-x center text-center profile-drawer">
          <div>
            <Avatar
              size={100}
              style={{ color: "#fffff", backgroundColor: "#000000" }}
              className="mt-10"
              src={Profile}
            >
              <div style={{ fontWeight: "400", fontSize: "2rem" }}>
                {/* {UserData.fullname.split(" ")[0].charAt(0).toUpperCase()} */}
              </div>
            </Avatar>
            <div className="mt-5 text-2xl font-medium">
              {admindetails?.name || "Website Admin"}
            </div>
            {/* <div className="an-24 regular-text mt20">{UserData?.siteName}</div> */}
            <div className="text-slate-500">
              {admindetails?.email || "admin@test.com"}
            </div>
            <Button
              danger
              htmlType="submit"
              className="mt-5 w-40 h-10"
              onClick={handleLogout}
            >
              Logout
            </Button>
          </div>
        </div>
        <hr className="my30" style={{ background: "#E4E8F0" }} />
      </Drawer>
      <Modal
        open={editId}
        width={!isMobile() && 600}
        okText="Submit"
        footer={false}
        cancelButtonProps={{ style: { display: "none" } }}
        className="form-modal-title"
        onCancel={() => {
          setEditId(null);
          form?.resetFields();
        }}
      >
        <RegistrationCard
          form={form}
          admindetails={admindetails}
          editId={editId}
          setEditId={setEditId}
          setRefresh={setRefresh}
        />
      </Modal>
    </>
  );
}

export default AppLayout;