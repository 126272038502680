import { Button, Card, Image } from 'antd'
import React, { useState } from 'react'
import { buttonChange, notesCard, statusCard } from '../../../../util/functions'
import { MdOutlineCalendarMonth } from 'react-icons/md'
import loadIcon from "../../../../asset/image/loading.svg";
import TaskApply from './task-apply';
import moment from 'moment';
import { useParams } from 'react-router-dom';


const RequestTask = ({ admindetails, taskData, status = "", applyUserTaskHandler, setStatus, setRefresh }) => {
    const [show, setShow] = useState(false);
    const { userTaskId, userId } = useParams();

    const isModalVisible = () => {
        setShow(pr => !pr);
    }

    return (
        <>
            <Card>
                <div className="flex justify-between items-center py-2">
                    <div className="font-semibold text-lg text-primary flex items-center"><Image preview={false} src={loadIcon} /><p className="ml-2">{taskData?.completionTime} min</p></div>
                    <div>
                        <p className="text-[#7D8995] font-semibold">ID : {userTaskId}</p>
                    </div>
                </div>
                <div className="flex items-center justify-between py-2 pb-3">
                    <div >
                        <p className="text-[#7D8995] text-xs">Expiry Date</p>
                        <div className="flex items-center mt-1"><MdOutlineCalendarMonth size={20} /><p className="ml-2">{moment(taskData?.expireDate).format("DD-MM-YYYY")}</p></div>
                    </div>
                    {taskData?.userTasks && taskData?.userTasks?.length > 0 && statusCard(status)}
                </div>
                {taskData?.userTasks && taskData?.userTasks?.length > 0 && notesCard(status, taskData?.userTasks[0])}
                {/* <div className="mt-16"> */}
                {buttonChange({ isModalVisible: () => isModalVisible(), status: taskData?.userTasks?.length < 0 ? "Apply" : status, applyUserTaskHandler: () => applyUserTaskHandler, show: show, setShow: setShow })}
                {/* </div> */}

                <TaskApply admindetails={admindetails} setRefresh={setRefresh} isModalVisible={isModalVisible} applyUserTaskHandler={applyUserTaskHandler} show={show} status={taskData?.userTasks?.length < 0 ? "Apply" : status} setStatus={setStatus} taskData={taskData} />
            </Card>
        </>
    )
}

export default RequestTask